<!--wrapper start here-->
<div class="login-wrapper">
    <div class="error-box error">
        Entered email is not registered with us. Please check your email and try again.
    </div>
    <div class="login-container">
        <div class="row">
            <div class="col-md-6 login-content-1">
                <figure class="logo">
                    <img src="../../../assets/images/ShowToStreamLogo.svg">
                </figure>
            </div>
            <div class="col-md-6 login-content-2">
                <div class="form-bx login-form " *ngIf="isLogin">
                    <h2>Login to account </h2>
                    <form [formGroup]="form" (ngSubmit)="loginFun()">
                        <div class="form-group">  
                            <label>Email</label>
                            <input type="email" placeholder="Email" autofocus="" formControlName="email" [(ngModel)]="loginModel.email">
                            <div class="error_msg" *ngIf="form.controls['email'].hasError('required') && showError">
                                *This field is required.
                            </div>
                            <div class="error_msg" *ngIf="form.controls['email'].hasError('email') && showError">
                                *Please enter valid email address.
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" placeholder="Password" formControlName="password" [(ngModel)]="loginModel.password" maxlength="20">
                            <div class="error_msg" *ngIf="form.controls['password'].hasError('required') && showError">
                                *This field is required.
                            </div>
                        </div>
                        <div class="form-group forgot-pwd">
                            <label class="checkbox leftlabel">
                                <input type="checkbox" [checked]="isRemember" (change)="changeRememberme($event)">
                                <i class="input-helper"></i>Remember me
                            </label>
                            
                            <a id="moveleft" href="javascript:void(0)" (click)="isLogin = !isLogin;" class="linkright flip-change">Forgot Password?</a>
                        </div>	
                        <div class="form-group">
                            <button type="submit" class="submit button"><span></span>Log In</button>
                        </div>
                    </form>
                </div>

                <!-- Forgot Password -->
                <div class="form-bx login-form" *ngIf="!isLogin">
                    <h2> Forgot password </h2>
                    <p class="message"> Please enter your email address. You will receive a link to create a new password via email. </p>
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()"> 
                        <div class="form-group">  
                            <label>Email</label>
                            <input type="email" placeholder="Email" formControlName="email" [(ngModel)]="resetPasswordModel.email" autocomplete="off" >
                            <div class="error_msg" *ngIf="resetPasswordForm.controls['email'].hasError('required') && showErrorForgot">
                                *This field is required.
                            </div>
                            <div class="error_msg" *ngIf="resetPasswordForm.controls['email'].hasError('email') && showErrorForgot">
                                *Please enter a valid email address.
                            </div>
                        </div>
                        <div class="form-group"></div>	
                        <div class="form-group">
                            <button type="submit" class="submit button"><span></span>Get New Password</button>
                        </div>
                        <div class="center"><div class="flip-back" (click)="isLogin = true;">Back to login</div></div>
                    </form>
                </div>
            </div>					
        </div>
    </div>

        
</div>
<!--wrapper start here end-->