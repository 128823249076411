export var DATE_REGEX = 'dd/MM/yyyy, hh:mm a';

export var MOBILE_REGEX = '^[0-9]*$';

export var NumberRegex = /^([1-9][0-9]{0,1})+(\.[0-9][0-9]{0,1})?/
// /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/ 
// /^[0-9]{0,2}|(\.[0-9]{0,2})$/

// export var NO_SPACE_REGEX = '[0-9a-zA-Z][0-9a-zA-Z ]+';

export var nonWhiteSpaceRegExp = '^[a-zA-Z]+$';

export var EMAIL_REGEX =/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
// export var SPACE_REGEX =  /^[a-zA-Z][a-zA-Z0-9. ]+$/;
//export var SPACE_REGEX =  /^[a-zA-Z0-9]{1}/;
export var SPACE_REGEX = /^[a-zA-Z][a-zA-Z. ]+$/;

export var Percentage_REGEX =  /^([1-9]|[1-9][0-9]|100)$/;

export var PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,8}$/g;

export let URL_REGEX = /^(http?|ftp|https):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(app|com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

export var LIMIT = 10;
export var pLIMIT = 6;

export var MAX_SIZE = 3;

export var MAX_IMAGE_SIZE = 5000000;

export const USER_STATUS_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Active Users', selected: false }, { key: 2, value: 'Deactivated Users', selected: false }];

export const GENDER_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Male', selected: false },
  { key: 2, value: 'Female', selected: false }];

export const AGE_FILTER_FROM = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: '10', selected: false },
  { key: 2, value: '20', selected: false }];

export const LIMIT_RECORD_LIST = [{ key: 1, value: 10, selected: true },
  { key: 2, value: 20, selected: false }, { key: 3, value: 50, selected: false }, { key: 4, value: 100, selected: false },
  { key: 5, value: 200, selected: false }];

export const PLAN_FILTER = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: 'Free', selected: false },
  { key: 2, value: 'Premium', selected: false }];

export const AGE_FILTER_TO = [{ key: 0, value: 'Select', selected: true }, { key: 1, value: '20', selected: false },
  { key: 2, value: '30', selected: false }];



export const TIME_ZONE = [
  {
    "value": "Dateline Standard Time",
    "abbr": "DST",
    "offset": -12,
    "isdst": false,
    "text": "(UTC-12:00) International Date Line West",
    "utc": [
      "Etc/GMT+12"
    ]
  },
  {
    "value": "UTC-11",
    "abbr": "U",
    "offset": -11,
    "isdst": false,
    "text": "(UTC-11:00) Coordinated Universal Time-11",
    "utc": [
      "Etc/GMT+11",
      "Pacific/Midway",
      "Pacific/Niue",
      "Pacific/Pago_Pago"
    ]
  },
  {
    "value": "Hawaiian Standard Time",
    "abbr": "HST",
    "offset": -10,
    "isdst": false,
    "text": "(UTC-10:00) Hawaii",
    "utc": [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ]
  },
  {
    "value": "Alaskan Standard Time",
    "abbr": "AKDT",
    "offset": -8,
    "isdst": true,
    "text": "(UTC-09:00) Alaska",
    "utc": [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    "value": "Pacific Standard Time (Mexico)",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-08:00) Baja California",
    "utc": [
      "America/Santa_Isabel"
    ]
  },
  {
    "value": "Pacific Daylight Time",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-07:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse"
    ]
  },
  {
    "value": "Pacific Standard Time",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "text": "(UTC-08:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse",
      "PST8PDT"
    ]
  },
  {
    "value": "US Mountain Standard Time",
    "abbr": "UMST",
    "offset": -7,
    "isdst": false,
    "text": "(UTC-07:00) Arizona",
    "utc": [
      "America/Creston",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "Etc/GMT+7"
    ]
  },
  {
    "value": "Mountain Standard Time (Mexico)",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "utc": [
      "America/Chihuahua",
      "America/Mazatlan"
    ]
  },
  {
    "value": "Mountain Standard Time",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Mountain Time (US & Canada)",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    "value": "Central America Standard Time",
    "abbr": "CAST",
    "offset": -6,
    "isdst": false,
    "text": "(UTC-06:00) Central America",
    "utc": [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos"
    ]
  },
  {
    "value": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "value": "Central Standard Time (Mexico)",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "utc": [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey"
    ]
  },
  {
    "value": "Canada Central Standard Time",
    "abbr": "CCST",
    "offset": -6,
    "isdst": false,
    "text": "(UTC-06:00) Saskatchewan",
    "utc": [
      "America/Regina",
      "America/Swift_Current"
    ]
  },
  {
    "value": "SA Pacific Standard Time",
    "abbr": "SPST",
    "offset": -5,
    "isdst": false,
    "text": "(UTC-05:00) Bogota, Lima, Quito",
    "utc": [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5"
    ]
  },
  {
    "value": "Eastern Standard Time",
    "abbr": "EDT",
    "offset": -4,
    "isdst": true,
    "text": "(UTC-05:00) Eastern Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
      "EST5EDT"
    ]
  },
  {
    "value": "US Eastern Standard Time",
    "abbr": "UEDT",
    "offset": -4,
    "isdst": true,
    "text": "(UTC-05:00) Indiana (East)",
    "utc": [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis"
    ]
  },
  {
    "value": "Venezuela Standard Time",
    "abbr": "VST",
    "offset": -4.5,
    "isdst": false,
    "text": "(UTC-04:30) Caracas",
    "utc": [
      "America/Caracas"
    ]
  },
  {
    "value": "Paraguay Standard Time",
    "abbr": "PYT",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Asuncion",
    "utc": [
      "America/Asuncion"
    ]
  },
  {
    "value": "Atlantic Standard Time",
    "abbr": "ADT",
    "offset": -3,
    "isdst": true,
    "text": "(UTC-04:00) Atlantic Time (Canada)",
    "utc": [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda"
    ]
  },
  {
    "value": "Central Brazilian Standard Time",
    "abbr": "CBST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Cuiaba",
    "utc": [
      "America/Campo_Grande",
      "America/Cuiaba"
    ]
  },
  {
    "value": "SA Western Standard Time",
    "abbr": "SWST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "utc": [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4"
    ]
  },
  {
    "value": "Pacific SA Standard Time",
    "abbr": "PSST",
    "offset": -4,
    "isdst": false,
    "text": "(UTC-04:00) Santiago",
    "utc": [
      "America/Santiago",
      "Antarctica/Palmer"
    ]
  },
  {
    "value": "Newfoundland Standard Time",
    "abbr": "NDT",
    "offset": -2.5,
    "isdst": true,
    "text": "(UTC-03:30) Newfoundland",
    "utc": [
      "America/St_Johns"
    ]
  },
  {
    "value": "E. South America Standard Time",
    "abbr": "ESAST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Brasilia",
    "utc": [
      "America/Sao_Paulo"
    ]
  },
  {
    "value": "Argentina Standard Time",
    "abbr": "AST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Buenos Aires",
    "utc": [
      "America/Argentina/La_Rioja",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza"
    ]
  },
  {
    "value": "SA Eastern Standard Time",
    "abbr": "SEST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Cayenne, Fortaleza",
    "utc": [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3"
    ]
  },
  {
    "value": "Greenland Standard Time",
    "abbr": "GDT",
    "offset": -3,
    "isdst": true,
    "text": "(UTC-03:00) Greenland",
    "utc": [
      "America/Godthab"
    ]
  },
  {
    "value": "Montevideo Standard Time",
    "abbr": "MST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Montevideo",
    "utc": [
      "America/Montevideo"
    ]
  },
  {
    "value": "Bahia Standard Time",
    "abbr": "BST",
    "offset": -3,
    "isdst": false,
    "text": "(UTC-03:00) Salvador",
    "utc": [
      "America/Bahia"
    ]
  },
  {
    "value": "UTC-02",
    "abbr": "U",
    "offset": -2,
    "isdst": false,
    "text": "(UTC-02:00) Coordinated Universal Time-02",
    "utc": [
      "America/Noronha",
      "Atlantic/South_Georgia",
      "Etc/GMT+2"
    ]
  },
  {
    "value": "Mid-Atlantic Standard Time",
    "abbr": "MDT",
    "offset": -1,
    "isdst": true,
    "text": "(UTC-02:00) Mid-Atlantic - Old",
    "utc": []
  },
  {
    "value": "Azores Standard Time",
    "abbr": "ADT",
    "offset": 0,
    "isdst": true,
    "text": "(UTC-01:00) Azores",
    "utc": [
      "America/Scoresbysund",
      "Atlantic/Azores"
    ]
  },
  {
    "value": "Cape Verde Standard Time",
    "abbr": "CVST",
    "offset": -1,
    "isdst": false,
    "text": "(UTC-01:00) Cape Verde Is.",
    "utc": [
      "Atlantic/Cape_Verde",
      "Etc/GMT+1"
    ]
  },
  {
    "value": "Morocco Standard Time",
    "abbr": "MDT",
    "offset": 1,
    "isdst": true,
    "text": "(UTC) Casablanca",
    "utc": [
      "Africa/Casablanca",
      "Africa/El_Aaiun"
    ]
  },
  {
    "value": "UTC",
    "abbr": "UTC",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Coordinated Universal Time",
    "utc": [
      "America/Danmarkshavn",
      "Etc/GMT"
    ]
  },
  {
    "value": "Greenwich Mean Time",
    "abbr": "GMT",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Edinburgh, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "value": "British Summer Time",
    "abbr": "BST",
    "offset": 1,
    "isdst": true,
    "text": "(UTC+01:00) Edinburgh, London",
    "utc": [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London"
    ]
  },
  {
    "value": "GMT Standard Time",
    "abbr": "GDT",
    "offset": 1,
    "isdst": true,
    "text": "(UTC) Dublin, Lisbon",
    "utc": [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon"
    ]
  },
  {
    "value": "Greenwich Standard Time",
    "abbr": "GST",
    "offset": 0,
    "isdst": false,
    "text": "(UTC) Monrovia, Reykjavik",
    "utc": [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena"
    ]
  },
  {
    "value": "W. Europe Standard Time",
    "abbr": "WEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "utc": [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich"
    ]
  },
  {
    "value": "Central Europe Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "utc": [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane"
    ]
  },
  {
    "value": "Romance Standard Time",
    "abbr": "RDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "utc": [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris"
    ]
  },
  {
    "value": "Central European Standard Time",
    "abbr": "CEDT",
    "offset": 2,
    "isdst": true,
    "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "utc": [
      "Europe/Sarajevo",
      "Europe/Skopje",
      "Europe/Warsaw",
      "Europe/Zagreb"
    ]
  },
  {
    "value": "W. Central Africa Standard Time",
    "abbr": "WCAST",
    "offset": 1,
    "isdst": false,
    "text": "(UTC+01:00) West Central Africa",
    "utc": [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1"
    ]
  },
  {
    "value": "Namibia Standard Time",
    "abbr": "NST",
    "offset": 1,
    "isdst": false,
    "text": "(UTC+01:00) Windhoek",
    "utc": [
      "Africa/Windhoek"
    ]
  },
  {
    "value": "GTB Standard Time",
    "abbr": "GDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Athens, Bucharest",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau"
    ]
  },
  {
    "value": "Middle East Standard Time",
    "abbr": "MEDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Beirut",
    "utc": [
      "Asia/Beirut"
    ]
  },
  {
    "value": "Egypt Standard Time",
    "abbr": "EST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Cairo",
    "utc": [
      "Africa/Cairo"
    ]
  },
  {
    "value": "Syria Standard Time",
    "abbr": "SDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Damascus",
    "utc": [
      "Asia/Damascus"
    ]
  },
  {
    "value": "E. Europe Standard Time",
    "abbr": "EEDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) E. Europe",
    "utc": [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"

    ]
  },
  {
    "value": "South Africa Standard Time",
    "abbr": "SAST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Harare, Pretoria",
    "utc": [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2"
    ]
  },
  {
    "value": "FLE Standard Time",
    "abbr": "FDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "utc": [
      "Europe/Helsinki",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"
    ]
  },
  {
    "value": "Turkey Standard Time",
    "abbr": "TDT",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Istanbul",
    "utc": [
      "Europe/Istanbul"
    ]
  },
  {
    "value": "Israel Standard Time",
    "abbr": "JDT",
    "offset": 3,
    "isdst": true,
    "text": "(UTC+02:00) Jerusalem",
    "utc": [
      "Asia/Jerusalem"
    ]
  },
  {
    "value": "Libya Standard Time",
    "abbr": "LST",
    "offset": 2,
    "isdst": false,
    "text": "(UTC+02:00) Tripoli",
    "utc": [
      "Africa/Tripoli"
    ]
  },
  {
    "value": "Jordan Standard Time",
    "abbr": "JST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Amman",
    "utc": [
      "Asia/Amman"
    ]
  },
  {
    "value": "Arabic Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Baghdad",
    "utc": [
      "Asia/Baghdad"
    ]
  },
  {
    "value": "Kaliningrad Standard Time",
    "abbr": "KST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Kaliningrad, Minsk",
    "utc": [
      "Europe/Kaliningrad",
      "Europe/Minsk"
    ]
  },
  {
    "value": "Arab Standard Time",
    "abbr": "AST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Kuwait, Riyadh",
    "utc": [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh"
    ]
  },
  {
    "value": "E. Africa Standard Time",
    "abbr": "EAST",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Nairobi",
    "utc": [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ]
  },
  {
    "value": "Moscow Standard Time",
    "abbr": "MSK",
    "offset": 3,
    "isdst": false,
    "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
    "utc": [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd"
    ]
  },
  {
    "value": "Samara Time",
    "abbr": "SAMT",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    "utc": [
      "Europe/Astrakhan",
      "Europe/Samara",
      "Europe/Ulyanovsk"
    ]
  },
  {
    "value": "Iran Standard Time",
    "abbr": "IDT",
    "offset": 4.5,
    "isdst": true,
    "text": "(UTC+03:30) Tehran",
    "utc": [
      "Asia/Tehran"
    ]
  },
  {
    "value": "Arabian Standard Time",
    "abbr": "AST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Abu Dhabi, Muscat",
    "utc": [
      "Asia/Dubai",
      "Asia/Muscat",
      "Etc/GMT-4"
    ]
  },
  {
    "value": "Azerbaijan Standard Time",
    "abbr": "ADT",
    "offset": 5,
    "isdst": true,
    "text": "(UTC+04:00) Baku",
    "utc": [
      "Asia/Baku"
    ]
  },
  {
    "value": "Mauritius Standard Time",
    "abbr": "MST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Port Louis",
    "utc": [
      "Indian/Mahe",
      "Indian/Mauritius",
      "Indian/Reunion"
    ]
  },
  {
    "value": "Georgian Standard Time",
    "abbr": "GST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Tbilisi",
    "utc": [
      "Asia/Tbilisi"
    ]
  },
  {
    "value": "Caucasus Standard Time",
    "abbr": "CST",
    "offset": 4,
    "isdst": false,
    "text": "(UTC+04:00) Yerevan",
    "utc": [
      "Asia/Yerevan"
    ]
  },
  {
    "value": "Afghanistan Standard Time",
    "abbr": "AST",
    "offset": 4.5,
    "isdst": false,
    "text": "(UTC+04:30) Kabul",
    "utc": [
      "Asia/Kabul"
    ]
  },
  {
    "value": "West Asia Standard Time",
    "abbr": "WAST",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Ashgabat, Tashkent",
    "utc": [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives"
    ]
  },
  {
    "value": "Pakistan Standard Time",
    "abbr": "PKT",
    "offset": 5,
    "isdst": false,
    "text": "(UTC+05:00) Islamabad, Karachi",
    "utc": [
      "Asia/Karachi"
    ]
  },
  {
    "value": "India Standard Time",
    "abbr": "IST",
    "offset": 5.5,
    "isdst": false,
    "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "utc": [
      "Asia/Kolkata"
    ]
  },
  {
    "value": "Sri Lanka Standard Time",
    "abbr": "SLST",
    "offset": 5.5,
    "isdst": false,
    "text": "(UTC+05:30) Sri Jayawardenepura",
    "utc": [
      "Asia/Colombo"
    ]
  },
  {
    "value": "Nepal Standard Time",
    "abbr": "NST",
    "offset": 5.75,
    "isdst": false,
    "text": "(UTC+05:45) Kathmandu",
    "utc": [
      "Asia/Katmandu"
    ]
  },
  {
    "value": "Central Asia Standard Time",
    "abbr": "CAST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Astana",
    "utc": [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos"
    ]
  },
  {
    "value": "Bangladesh Standard Time",
    "abbr": "BST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Dhaka",
    "utc": [
      "Asia/Dhaka",
      "Asia/Thimphu"
    ]
  },
  {
    "value": "Ekaterinburg Standard Time",
    "abbr": "EST",
    "offset": 6,
    "isdst": false,
    "text": "(UTC+06:00) Ekaterinburg",
    "utc": [
      "Asia/Yekaterinburg"
    ]
  },
  {
    "value": "Myanmar Standard Time",
    "abbr": "MST",
    "offset": 6.5,
    "isdst": false,
    "text": "(UTC+06:30) Yangon (Rangoon)",
    "utc": [
      "Asia/Rangoon",
      "Indian/Cocos"
    ]
  },
  {
    "value": "SE Asia Standard Time",
    "abbr": "SAST",
    "offset": 7,
    "isdst": false,
    "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "utc": [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas"
    ]
  },
  {
    "value": "N. Central Asia Standard Time",
    "abbr": "NCAST",
    "offset": 7,
    "isdst": false,
    "text": "(UTC+07:00) Novosibirsk",
    "utc": [
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk"
    ]
  },
  {
    "value": "China Standard Time",
    "abbr": "CST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "utc": [
      "Asia/Hong_Kong",
      "Asia/Macau",
      "Asia/Shanghai"
    ]
  },
  {
    "value": "North Asia Standard Time",
    "abbr": "NAST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Krasnoyarsk",
    "utc": [
      "Asia/Krasnoyarsk"
    ]
  },
  {
    "value": "Singapore Standard Time",
    "abbr": "MPST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Kuala Lumpur, Singapore",
    "utc": [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8"
    ]
  },
  {
    "value": "W. Australia Standard Time",
    "abbr": "WAST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Perth",
    "utc": [
      "Antarctica/Casey",
      "Australia/Perth"
    ]
  },
  {
    "value": "Taipei Standard Time",
    "abbr": "TST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Taipei",
    "utc": [
      "Asia/Taipei"
    ]
  },
  {
    "value": "Ulaanbaatar Standard Time",
    "abbr": "UST",
    "offset": 8,
    "isdst": false,
    "text": "(UTC+08:00) Ulaanbaatar",
    "utc": [
      "Asia/Choibalsan",
      "Asia/Ulaanbaatar"
    ]
  },
  {
    "value": "North Asia East Standard Time",
    "abbr": "NAEST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Irkutsk",
    "utc": [
      "Asia/Irkutsk"
    ]
  },
  {
    "value": "Japan Standard Time",
    "abbr": "JST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "utc": [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau"
    ]
  },
  {
    "value": "Korea Standard Time",
    "abbr": "KST",
    "offset": 9,
    "isdst": false,
    "text": "(UTC+09:00) Seoul",
    "utc": [
      "Asia/Pyongyang",
      "Asia/Seoul"
    ]
  },
  {
    "value": "Cen. Australia Standard Time",
    "abbr": "CAST",
    "offset": 9.5,
    "isdst": false,
    "text": "(UTC+09:30) Adelaide",
    "utc": [
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ]
  },
  {
    "value": "AUS Central Standard Time",
    "abbr": "ACST",
    "offset": 9.5,
    "isdst": false,
    "text": "(UTC+09:30) Darwin",
    "utc": [
      "Australia/Darwin"
    ]
  },
  {
    "value": "E. Australia Standard Time",
    "abbr": "EAST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Brisbane",
    "utc": [
      "Australia/Brisbane",
      "Australia/Lindeman"
    ]
  },
  {
    "value": "AUS Eastern Standard Time",
    "abbr": "AEST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
    "utc": [
      "Australia/Melbourne",
      "Australia/Sydney"
    ]
  },
  {
    "value": "West Pacific Standard Time",
    "abbr": "WPST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Guam, Port Moresby",
    "utc": [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk"
    ]
  },
  {
    "value": "Tasmania Standard Time",
    "abbr": "TST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Hobart",
    "utc": [
      "Australia/Currie",
      "Australia/Hobart"
    ]
  },
  {
    "value": "Yakutsk Standard Time",
    "abbr": "YST",
    "offset": 10,
    "isdst": false,
    "text": "(UTC+10:00) Yakutsk",
    "utc": [
      "Asia/Chita",
      "Asia/Khandyga",
      "Asia/Yakutsk"
    ]
  },
  {
    "value": "Central Pacific Standard Time",
    "abbr": "CPST",
    "offset": 11,
    "isdst": false,
    "text": "(UTC+11:00) Solomon Is., New Caledonia",
    "utc": [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape"
    ]
  },
  {
    "value": "Vladivostok Standard Time",
    "abbr": "VST",
    "offset": 11,
    "isdst": false,
    "text": "(UTC+11:00) Vladivostok",
    "utc": [
      "Asia/Sakhalin",
      "Asia/Ust-Nera",
      "Asia/Vladivostok"
    ]
  },
  {
    "value": "New Zealand Standard Time",
    "abbr": "NZST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Auckland, Wellington",
    "utc": [
      "Antarctica/McMurdo",
      "Pacific/Auckland"
    ]
  },
  {
    "value": "UTC+12",
    "abbr": "U",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Coordinated Universal Time+12",
    "utc": [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis"
    ]
  },
  {
    "value": "Fiji Standard Time",
    "abbr": "FST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Fiji",
    "utc": [
      "Pacific/Fiji"
    ]
  },
  {
    "value": "Magadan Standard Time",
    "abbr": "MST",
    "offset": 12,
    "isdst": false,
    "text": "(UTC+12:00) Magadan",
    "utc": [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk"
    ]
  },
  {
    "value": "Kamchatka Standard Time",
    "abbr": "KDT",
    "offset": 13,
    "isdst": true,
    "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    "utc": [
      "Asia/Kamchatka"
    ]
  },
  {
    "value": "Tonga Standard Time",
    "abbr": "TST",
    "offset": 13,
    "isdst": false,
    "text": "(UTC+13:00) Nuku'alofa",
    "utc": [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu"
    ]
  },
  {
    "value": "Samoa Standard Time",
    "abbr": "SST",
    "offset": 13,
    "isdst": false,
    "text": "(UTC+13:00) Samoa",
    "utc": [
      "Pacific/Apia"
    ]
  }
];

export let COUNTRY_LIST = [
  {"name": "Select", "code": ""},
  {"name": "Afghanistan", "code": "AF"},
  {"name": "Aland Islands", "code": "AX"},
  {"name": "Albania", "code": "AL"},
  {"name": "Algeria", "code": "DZ"},
  {"name": "American Samoa", "code": "AS"},
  {"name": "AndorrA", "code": "AD"},
  {"name": "Angola", "code": "AO"},
  {"name": "Anguilla", "code": "AI"},
  {"name": "Antarctica", "code": "AQ"},
  {"name": "Antigua and Barbuda", "code": "AG"},
  {"name": "Argentina", "code": "AR"},
  {"name": "Armenia", "code": "AM"},
  {"name": "Aruba", "code": "AW"},
  {"name": "Australia", "code": "AU"},
  {"name": "Austria", "code": "AT"},
  {"name": "Azerbaijan", "code": "AZ"},
  {"name": "Bahamas", "code": "BS"},
  {"name": "Bahrain", "code": "BH"},
  {"name": "Bangladesh", "code": "BD"},
  {"name": "Barbados", "code": "BB"},
  {"name": "Belarus", "code": "BY"},
  {"name": "Belgium", "code": "BE"},
  {"name": "Belize", "code": "BZ"},
  {"name": "Benin", "code": "BJ"},
  {"name": "Bermuda", "code": "BM"},
  {"name": "Bhutan", "code": "BT"},
  {"name": "Bolivia", "code": "BO"},
  {"name": "Bosnia and Herzegovina", "code": "BA"},
  {"name": "Botswana", "code": "BW"},
  {"name": "Bouvet Island", "code": "BV"},
  {"name": "Brazil", "code": "BR"},
  {"name": "British Indian Ocean Territory", "code": "IO"},
  {"name": "Brunei Darussalam", "code": "BN"},
  {"name": "Bulgaria", "code": "BG"},
  {"name": "Burkina Faso", "code": "BF"},
  {"name": "Burundi", "code": "BI"},
  {"name": "Cambodia", "code": "KH"},
  {"name": "Cameroon", "code": "CM"},
  {"name": "Canada", "code": "CA"},
  {"name": "Cape Verde", "code": "CV"},
  {"name": "Cayman Islands", "code": "KY"},
  {"name": "Central African Republic", "code": "CF"},
  {"name": "Chad", "code": "TD"},
  {"name": "Chile", "code": "CL"},
  {"name": "China", "code": "CN"},
  {"name": "Christmas Island", "code": "CX"},
  {"name": "Cocos (Keeling) Islands", "code": "CC"},
  {"name": "Colombia", "code": "CO"},
  {"name": "Comoros", "code": "KM"},
  {"name": "Congo", "code": "CG"},
  {"name": "Congo, The Democratic Republic of the", "code": "CD"},
  {"name": "Cook Islands", "code": "CK"},
  {"name": "Costa Rica", "code": "CR"},
  {"name": "Cote D\"Ivoire", "code": "CI"},
  {"name": "Croatia", "code": "HR"},
  {"name": "Cuba", "code": "CU"},
  {"name": "Cyprus", "code": "CY"},
  {"name": "Czech Republic", "code": "CZ"},
  {"name": "Denmark", "code": "DK"},
  {"name": "Djibouti", "code": "DJ"},
  {"name": "Dominica", "code": "DM"},
  {"name": "Dominican Republic", "code": "DO"},
  {"name": "Ecuador", "code": "EC"},
  {"name": "Egypt", "code": "EG"},
  {"name": "El Salvador", "code": "SV"},
  {"name": "Equatorial Guinea", "code": "GQ"},
  {"name": "Eritrea", "code": "ER"},
  {"name": "Estonia", "code": "EE"},
  {"name": "Ethiopia", "code": "ET"},
  {"name": "Falkland Islands (Malvinas)", "code": "FK"},
  {"name": "Faroe Islands", "code": "FO"},
  {"name": "Fiji", "code": "FJ"},
  {"name": "Finland", "code": "FI"},
  {"name": "France", "code": "FR"},
  {"name": "French Guiana", "code": "GF"},
  {"name": "French Polynesia", "code": "PF"},
  {"name": "French Southern Territories", "code": "TF"},
  {"name": "Gabon", "code": "GA"},
  {"name": "Gambia", "code": "GM"},
  {"name": "Georgia", "code": "GE"},
  {"name": "Germany", "code": "DE"},
  {"name": "Ghana", "code": "GH"},
  {"name": "Gibraltar", "code": "GI"},
  {"name": "Greece", "code": "GR"},
  {"name": "Greenland", "code": "GL"},
  {"name": "Grenada", "code": "GD"},
  {"name": "Guadeloupe", "code": "GP"},
  {"name": "Guam", "code": "GU"},
  {"name": "Guatemala", "code": "GT"},
  {"name": "Guernsey", "code": "GG"},
  {"name": "Guinea", "code": "GN"},
  {"name": "Guinea-Bissau", "code": "GW"},
  {"name": "Guyana", "code": "GY"},
  {"name": "Haiti", "code": "HT"},
  {"name": "Heard Island and Mcdonald Islands", "code": "HM"},
  {"name": "Holy See (Vatican City State)", "code": "VA"},
  {"name": "Honduras", "code": "HN"},
  {"name": "Hong Kong", "code": "HK"},
  {"name": "Hungary", "code": "HU"},
  {"name": "Iceland", "code": "IS"},
  {"name": "India", "code": "IN"},
  {"name": "Indonesia", "code": "ID"},
  {"name": "Iran, Islamic Republic Of", "code": "IR"},
  {"name": "Iraq", "code": "IQ"},
  {"name": "Ireland", "code": "IE"},
  {"name": "Isle of Man", "code": "IM"},
  {"name": "Israel", "code": "IL"},
  {"name": "Italy", "code": "IT"},
  {"name": "Jamaica", "code": "JM"},
  {"name": "Japan", "code": "JP"},
  {"name": "Jersey", "code": "JE"},
  {"name": "Jordan", "code": "JO"},
  {"name": "Kazakhstan", "code": "KZ"},
  {"name": "Kenya", "code": "KE"},
  {"name": "Kiribati", "code": "KI"},
  {"name": "Korea, Democratic People\"S Republic of", "code": "KP"},
  {"name": "Korea, Republic of", "code": "KR"},
  {"name": "Kuwait", "code": "KW"},
  {"name": "Kyrgyzstan", "code": "KG"},
  {"name": "Lao People\"S Democratic Republic", "code": "LA"},
  {"name": "Latvia", "code": "LV"},
  {"name": "Lebanon", "code": "LB"},
  {"name": "Lesotho", "code": "LS"},
  {"name": "Liberia", "code": "LR"},
  {"name": "Libyan Arab Jamahiriya", "code": "LY"},
  {"name": "Liechtenstein", "code": "LI"},
  {"name": "Lithuania", "code": "LT"},
  {"name": "Luxembourg", "code": "LU"},
  {"name": "Macao", "code": "MO"},
  {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"},
  {"name": "Madagascar", "code": "MG"},
  {"name": "Malawi", "code": "MW"},
  {"name": "Malaysia", "code": "MY"},
  {"name": "Maldives", "code": "MV"},
  {"name": "Mali", "code": "ML"},
  {"name": "Malta", "code": "MT"},
  {"name": "Marshall Islands", "code": "MH"},
  {"name": "Martinique", "code": "MQ"},
  {"name": "Mauritania", "code": "MR"},
  {"name": "Mauritius", "code": "MU"},
  {"name": "Mayotte", "code": "YT"},
  {"name": "Mexico", "code": "MX"},
  {"name": "Micronesia, Federated States of", "code": "FM"},
  {"name": "Moldova, Republic of", "code": "MD"},
  {"name": "Monaco", "code": "MC"},
  {"name": "Mongolia", "code": "MN"},
  {"name": "Montenegro", "code": "ME"},
  {"name": "Montserrat", "code": "MS"},
  {"name": "Morocco", "code": "MA"},
  {"name": "Mozambique", "code": "MZ"},
  {"name": "Myanmar", "code": "MM"},
  {"name": "Namibia", "code": "NA"},
  {"name": "Nauru", "code": "NR"},
  {"name": "Nepal", "code": "NP"},
  {"name": "Netherlands", "code": "NL"},
  {"name": "Netherlands Antilles", "code": "AN"},
  {"name": "New Caledonia", "code": "NC"},
  {"name": "New Zealand", "code": "NZ"},
  {"name": "Nicaragua", "code": "NI"},
  {"name": "Niger", "code": "NE"},
  {"name": "Nigeria", "code": "NG"},
  {"name": "Niue", "code": "NU"},
  {"name": "Norfolk Island", "code": "NF"},
  {"name": "Northern Mariana Islands", "code": "MP"},
  {"name": "Norway", "code": "NO"},
  {"name": "Oman", "code": "OM"},
  {"name": "Pakistan", "code": "PK"},
  {"name": "Palau", "code": "PW"},
  {"name": "Palestinian Territory, Occupied", "code": "PS"},
  {"name": "Panama", "code": "PA"},
  {"name": "Papua New Guinea", "code": "PG"},
  {"name": "Paraguay", "code": "PY"},
  {"name": "Peru", "code": "PE"},
  {"name": "Philippines", "code": "PH"},
  {"name": "Pitcairn", "code": "PN"},
  {"name": "Poland", "code": "PL"},
  {"name": "Portugal", "code": "PT"},
  {"name": "Puerto Rico", "code": "PR"},
  {"name": "Qatar", "code": "QA"},
  {"name": "Reunion", "code": "RE"},
  {"name": "Romania", "code": "RO"},
  {"name": "Russian Federation", "code": "RU"},
  {"name": "RWANDA", "code": "RW"},
  {"name": "Saint Helena", "code": "SH"},
  {"name": "Saint Kitts and Nevis", "code": "KN"},
  {"name": "Saint Lucia", "code": "LC"},
  {"name": "Saint Pierre and Miquelon", "code": "PM"},
  {"name": "Saint Vincent and the Grenadines", "code": "VC"},
  {"name": "Samoa", "code": "WS"},
  {"name": "San Marino", "code": "SM"},
  {"name": "Sao Tome and Principe", "code": "ST"},
  {"name": "Saudi Arabia", "code": "SA"},
  {"name": "Senegal", "code": "SN"},
  {"name": "Serbia", "code": "RS"},
  {"name": "Seychelles", "code": "SC"},
  {"name": "Sierra Leone", "code": "SL"},
  {"name": "Singapore", "code": "SG"},
  {"name": "Slovakia", "code": "SK"},
  {"name": "Slovenia", "code": "SI"},
  {"name": "Solomon Islands", "code": "SB"},
  {"name": "Somalia", "code": "SO"},
  {"name": "South Africa", "code": "ZA"},
  {"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
  {"name": "Spain", "code": "ES"},
  {"name": "Sri Lanka", "code": "LK"},
  {"name": "Sudan", "code": "SD"},
  {"name": "Suriname", "code": "SR"},
  {"name": "Svalbard and Jan Mayen", "code": "SJ"},
  {"name": "Swaziland", "code": "SZ"},
  {"name": "Sweden", "code": "SE"},
  {"name": "Switzerland", "code": "CH"},
  {"name": "Syrian Arab Republic", "code": "SY"},
  {"name": "Taiwan, Province of China", "code": "TW"},
  {"name": "Tajikistan", "code": "TJ"},
  {"name": "Tanzania, United Republic of", "code": "TZ"},
  {"name": "Thailand", "code": "TH"},
  {"name": "Timor-Leste", "code": "TL"},
  {"name": "Togo", "code": "TG"},
  {"name": "Tokelau", "code": "TK"},
  {"name": "Tonga", "code": "TO"},
  {"name": "Trinidad and Tobago", "code": "TT"},
  {"name": "Tunisia", "code": "TN"},
  {"name": "Turkey", "code": "TR"},
  {"name": "Turkmenistan", "code": "TM"},
  {"name": "Turks and Caicos Islands", "code": "TC"},
  {"name": "Tuvalu", "code": "TV"},
  {"name": "Uganda", "code": "UG"},
  {"name": "Ukraine", "code": "UA"},
  {"name": "United Arab Emirates", "code": "AE"},
  {"name": "United Kingdom", "code": "GB"},
  {"name": "United States", "code": "US"},
  {"name": "United States Minor Outlying Islands", "code": "UM"},
  {"name": "Uruguay", "code": "UY"},
  {"name": "Uzbekistan", "code": "UZ"},
  {"name": "Vanuatu", "code": "VU"},
  {"name": "Venezuela", "code": "VE"},
  {"name": "Viet Nam", "code": "VN"},
  {"name": "Virgin Islands, British", "code": "VG"},
  {"name": "Virgin Islands, U.S.", "code": "VI"},
  {"name": "Wallis and Futuna", "code": "WF"},
  {"name": "Western Sahara", "code": "EH"},
  {"name": "Yemen", "code": "YE"},
  {"name": "Zambia", "code": "ZM"},
  {"name": "Zimbabwe", "code": "ZW"}
];

export const country_code = [
  {
  "name": "Afghanistan",
  "dial_code": "+93",
  "code": "AF"
  },
  {
  "name": "Aland Islands",
  "dial_code": "+358",
  "code": "AX"
  },
  {
  "name": "Albania",
  "dial_code": "+355",
  "code": "AL"
  },
  {
  "name": "Algeria",
  "dial_code": "+213",
  "code": "DZ"
  },
  {
  "name": "AmericanSamoa",
  "dial_code": "+1684",
  "code": "AS"
  },
  {
  "name": "Andorra",
  "dial_code": "+376",
  "code": "AD"
  },
  {
  "name": "Angola",
  "dial_code": "+244",
  "code": "AO"
  },
  {
  "name": "Anguilla",
  "dial_code": "+1264",
  "code": "AI"
  },
  {
  "name": "Antarctica",
  "dial_code": "+672",
  "code": "AQ"
  },
  {
  "name": "Antigua and Barbuda",
  "dial_code": "+1268",
  "code": "AG"
  },
  {
  "name": "Argentina",
  "dial_code": "+54",
  "code": "AR"
  },
  {
  "name": "Armenia",
  "dial_code": "+374",
  "code": "AM"
  },
  {
  "name": "Aruba",
  "dial_code": "+297",
  "code": "AW"
  },
  {
  "name": "Australia",
  "dial_code": "+61",
  "code": "AU"
  },
  {
  "name": "Austria",
  "dial_code": "+43",
  "code": "AT"
  },
  {
  "name": "Azerbaijan",
  "dial_code": "+994",
  "code": "AZ"
  },
  {
  "name": "Bahamas",
  "dial_code": "+1242",
  "code": "BS"
  },
  {
  "name": "Bahrain",
  "dial_code": "+973",
  "code": "BH"
  },
  {
  "name": "Bangladesh",
  "dial_code": "+880",
  "code": "BD"
  },
  {
  "name": "Barbados",
  "dial_code": "+1246",
  "code": "BB"
  },
  {
  "name": "Belarus",
  "dial_code": "+375",
  "code": "BY"
  },
  {
  "name": "Belgium",
  "dial_code": "+32",
  "code": "BE"
  },
  {
  "name": "Belize",
  "dial_code": "+501",
  "code": "BZ"
  },
  {
  "name": "Benin",
  "dial_code": "+229",
  "code": "BJ"
  },
  {
  "name": "Bermuda",
  "dial_code": "+1441",
  "code": "BM"
  },
  {
  "name": "Bhutan",
  "dial_code": "+975",
  "code": "BT"
  },
  {
  "name": "Bolivia, Plurinational State of",
  "dial_code": "+591",
  "code": "BO"
  },
  {
  "name": "Bosnia and Herzegovina",
  "dial_code": "+387",
  "code": "BA"
  },
  {
  "name": "Botswana",
  "dial_code": "+267",
  "code": "BW"
  },
  {
  "name": "Brazil",
  "dial_code": "+55",
  "code": "BR"
  },
  {
  "name": "British Indian Ocean Territory",
  "dial_code": "+246",
  "code": "IO"
  },
  {
  "name": "Brunei Darussalam",
  "dial_code": "+673",
  "code": "BN"
  },
  {
  "name": "Bulgaria",
  "dial_code": "+359",
  "code": "BG"
  },
  {
  "name": "Burkina Faso",
  "dial_code": "+226",
  "code": "BF"
  },
  {
  "name": "Burundi",
  "dial_code": "+257",
  "code": "BI"
  },
  {
  "name": "Cambodia",
  "dial_code": "+855",
  "code": "KH"
  },
  {
  "name": "Cameroon",
  "dial_code": "+237",
  "code": "CM"
  },
  {
  "name": "Canada",
  "dial_code": "+1",
  "code": "CA"
  },
  {
  "name": "Cape Verde",
  "dial_code": "+238",
  "code": "CV"
  },
  {
  "name": "Cayman Islands",
  "dial_code": "+ 345",
  "code": "KY"
  },
  {
  "name": "Central African Republic",
  "dial_code": "+236",
  "code": "CF"
  },
  {
  "name": "Chad",
  "dial_code": "+235",
  "code": "TD"
  },
  {
  "name": "Chile",
  "dial_code": "+56",
  "code": "CL"
  },
  {
  "name": "China",
  "dial_code": "+86",
  "code": "CN"
  },
  {
  "name": "Christmas Island",
  "dial_code": "+61",
  "code": "CX"
  },
  {
  "name": "Cocos (Keeling) Islands",
  "dial_code": "+61",
  "code": "CC"
  },
  {
  "name": "Colombia",
  "dial_code": "+57",
  "code": "CO"
  },
  {
  "name": "Comoros",
  "dial_code": "+269",
  "code": "KM"
  },
  {
  "name": "Congo",
  "dial_code": "+242",
  "code": "CG"
  },
  {
  "name": "Congo, The Democratic Republic of the Congo",
  "dial_code": "+243",
  "code": "CD"
  },
  {
  "name": "Cook Islands",
  "dial_code": "+682",
  "code": "CK"
  },
  {
  "name": "Costa Rica",
  "dial_code": "+506",
  "code": "CR"
  },
  {
  "name": "Cote d'Ivoire",
  "dial_code": "+225",
  "code": "CI"
  },
  {
  "name": "Croatia",
  "dial_code": "+385",
  "code": "HR"
  },
  {
  "name": "Cuba",
  "dial_code": "+53",
  "code": "CU"
  },
  {
  "name": "Cyprus",
  "dial_code": "+357",
  "code": "CY"
  },
  {
  "name": "Czech Republic",
  "dial_code": "+420",
  "code": "CZ"
  },
  {
  "name": "Denmark",
  "dial_code": "+45",
  "code": "DK"
  },
  {
  "name": "Djibouti",
  "dial_code": "+253",
  "code": "DJ"
  },
  {
  "name": "Dominica",
  "dial_code": "+1767",
  "code": "DM"
  },
  {
  "name": "Dominican Republic",
  "dial_code": "+1849",
  "code": "DO"
  },
  {
  "name": "Ecuador",
  "dial_code": "+593",
  "code": "EC"
  },
  {
  "name": "Egypt",
  "dial_code": "+20",
  "code": "EG"
  },
  {
  "name": "El Salvador",
  "dial_code": "+503",
  "code": "SV"
  },
  {
  "name": "Equatorial Guinea",
  "dial_code": "+240",
  "code": "GQ"
  },
  {
  "name": "Eritrea",
  "dial_code": "+291",
  "code": "ER"
  },
  {
  "name": "Estonia",
  "dial_code": "+372",
  "code": "EE"
  },
  {
  "name": "Ethiopia",
  "dial_code": "+251",
  "code": "ET"
  },
  {
  "name": "Falkland Islands (Malvinas)",
  "dial_code": "+500",
  "code": "FK"
  },
  {
  "name": "Faroe Islands",
  "dial_code": "+298",
  "code": "FO"
  },
  {
  "name": "Fiji",
  "dial_code": "+679",
  "code": "FJ"
  },
  {
  "name": "Finland",
  "dial_code": "+358",
  "code": "FI"
  },
  {
  "name": "France",
  "dial_code": "+33",
  "code": "FR"
  },
  {
  "name": "French Guiana",
  "dial_code": "+594",
  "code": "GF"
  },
  {
  "name": "French Polynesia",
  "dial_code": "+689",
  "code": "PF"
  },
  {
  "name": "Gabon",
  "dial_code": "+241",
  "code": "GA"
  },
  {
  "name": "Gambia",
  "dial_code": "+220",
  "code": "GM"
  },
  {
  "name": "Georgia",
  "dial_code": "+995",
  "code": "GE"
  },
  {
  "name": "Germany",
  "dial_code": "+49",
  "code": "DE"
  },
  {
  "name": "Ghana",
  "dial_code": "+233",
  "code": "GH"
  },
  {
  "name": "Gibraltar",
  "dial_code": "+350",
  "code": "GI"
  },
  {
  "name": "Greece",
  "dial_code": "+30",
  "code": "GR"
  },
  {
  "name": "Greenland",
  "dial_code": "+299",
  "code": "GL"
  },
  {
  "name": "Grenada",
  "dial_code": "+1473",
  "code": "GD"
  },
  {
  "name": "Guadeloupe",
  "dial_code": "+590",
  "code": "GP"
  },
  {
  "name": "Guam",
  "dial_code": "+1671",
  "code": "GU"
  },
  {
  "name": "Guatemala",
  "dial_code": "+502",
  "code": "GT"
  },
  {
  "name": "Guernsey",
  "dial_code": "+44",
  "code": "GG"
  },
  {
  "name": "Guinea",
  "dial_code": "+224",
  "code": "GN"
  },
  {
  "name": "Guinea-Bissau",
  "dial_code": "+245",
  "code": "GW"
  },
  {
  "name": "Guyana",
  "dial_code": "+595",
  "code": "GY"
  },
  {
  "name": "Haiti",
  "dial_code": "+509",
  "code": "HT"
  },
  {
  "name": "Holy See (Vatican City State)",
  "dial_code": "+379",
  "code": "VA"
  },
  {
  "name": "Honduras",
  "dial_code": "+504",
  "code": "HN"
  },
  {
  "name": "Hong Kong",
  "dial_code": "+852",
  "code": "HK"
  },
  {
  "name": "Hungary",
  "dial_code": "+36",
  "code": "HU"
  },
  {
  "name": "Iceland",
  "dial_code": "+354",
  "code": "IS"
  },
  {
  "name": "India",
  "dial_code": "+91",
  "code": "IN"
  },
  {
  "name": "Indonesia",
  "dial_code": "+62",
  "code": "ID"
  },
  {
  "name": "Iran, Islamic Republic of Persian Gulf",
  "dial_code": "+98",
  "code": "IR"
  },
  {
  "name": "Iraq",
  "dial_code": "+964",
  "code": "IQ"
  },
  {
  "name": "Ireland",
  "dial_code": "+353",
  "code": "IE"
  },
  {
  "name": "Isle of Man",
  "dial_code": "+44",
  "code": "IM"
  },
  {
  "name": "Israel",
  "dial_code": "+972",
  "code": "IL"
  },
  {
  "name": "Italy",
  "dial_code": "+39",
  "code": "IT"
  },
  {
  "name": "Jamaica",
  "dial_code": "+1876",
  "code": "JM"
  },
  {
  "name": "Japan",
  "dial_code": "+81",
  "code": "JP"
  },
  {
  "name": "Jersey",
  "dial_code": "+44",
  "code": "JE"
  },
  {
  "name": "Jordan",
  "dial_code": "+962",
  "code": "JO"
  },
  {
  "name": "Kazakhstan",
  "dial_code": "+77",
  "code": "KZ"
  },
  {
  "name": "Kenya",
  "dial_code": "+254",
  "code": "KE"
  },
  {
  "name": "Kiribati",
  "dial_code": "+686",
  "code": "KI"
  },
  {
  "name": "Korea, Democratic People's Republic of Korea",
  "dial_code": "+850",
  "code": "KP"
  },
  {
  "name": "Korea, Republic of South Korea",
  "dial_code": "+82",
  "code": "KR"
  },
  {
  "name": "Kuwait",
  "dial_code": "+965",
  "code": "KW"
  },
  {
  "name": "Kyrgyzstan",
  "dial_code": "+996",
  "code": "KG"
  },
  {
  "name": "Laos",
  "dial_code": "+856",
  "code": "LA"
  },
  {
  "name": "Latvia",
  "dial_code": "+371",
  "code": "LV"
  },
  {
  "name": "Lebanon",
  "dial_code": "+961",
  "code": "LB"
  },
  {
  "name": "Lesotho",
  "dial_code": "+266",
  "code": "LS"
  },
  {
  "name": "Liberia",
  "dial_code": "+231",
  "code": "LR"
  },
  {
  "name": "Libyan Arab Jamahiriya",
  "dial_code": "+218",
  "code": "LY"
  },
  {
  "name": "Liechtenstein",
  "dial_code": "+423",
  "code": "LI"
  },
  {
  "name": "Lithuania",
  "dial_code": "+370",
  "code": "LT"
  },
  {
  "name": "Luxembourg",
  "dial_code": "+352",
  "code": "LU"
  },
  {
  "name": "Macao",
  "dial_code": "+853",
  "code": "MO"
  },
  {
  "name": "Macedonia",
  "dial_code": "+389",
  "code": "MK"
  },
  {
  "name": "Madagascar",
  "dial_code": "+261",
  "code": "MG"
  },
  {
  "name": "Malawi",
  "dial_code": "+265",
  "code": "MW"
  },
  {
  "name": "Malaysia",
  "dial_code": "+60",
  "code": "MY"
  },
  {
  "name": "Maldives",
  "dial_code": "+960",
  "code": "MV"
  },
  {
  "name": "Mali",
  "dial_code": "+223",
  "code": "ML"
  },
  {
  "name": "Malta",
  "dial_code": "+356",
  "code": "MT"
  },
  {
  "name": "Marshall Islands",
  "dial_code": "+692",
  "code": "MH"
  },
  {
  "name": "Martinique",
  "dial_code": "+596",
  "code": "MQ"
  },
  {
  "name": "Mauritania",
  "dial_code": "+222",
  "code": "MR"
  },
  {
  "name": "Mauritius",
  "dial_code": "+230",
  "code": "MU"
  },
  {
  "name": "Mayotte",
  "dial_code": "+262",
  "code": "YT"
  },
  {
  "name": "Mexico",
  "dial_code": "+52",
  "code": "MX"
  },
  {
  "name": "Micronesia, Federated States of Micronesia",
  "dial_code": "+691",
  "code": "FM"
  },
  {
  "name": "Moldova",
  "dial_code": "+373",
  "code": "MD"
  },
  {
  "name": "Monaco",
  "dial_code": "+377",
  "code": "MC"
  },
  {
  "name": "Mongolia",
  "dial_code": "+976",
  "code": "MN"
  },
  {
  "name": "Montenegro",
  "dial_code": "+382",
  "code": "ME"
  },
  {
  "name": "Montserrat",
  "dial_code": "+1664",
  "code": "MS"
  },
  {
  "name": "Morocco",
  "dial_code": "+212",
  "code": "MA"
  },
  {
  "name": "Mozambique",
  "dial_code": "+258",
  "code": "MZ"
  },
  {
  "name": "Myanmar",
  "dial_code": "+95",
  "code": "MM"
  },
  {
  "name": "Namibia",
  "dial_code": "+264",
  "code": "NA"
  },
  {
  "name": "Nauru",
  "dial_code": "+674",
  "code": "NR"
  },
  {
  "name": "Nepal",
  "dial_code": "+977",
  "code": "NP"
  },
  {
  "name": "Netherlands",
  "dial_code": "+31",
  "code": "NL"
  },
  {
  "name": "Netherlands Antilles",
  "dial_code": "+599",
  "code": "AN"
  },
  {
  "name": "New Caledonia",
  "dial_code": "+687",
  "code": "NC"
  },
  {
  "name": "New Zealand",
  "dial_code": "+64",
  "code": "NZ"
  },
  {
  "name": "Nicaragua",
  "dial_code": "+505",
  "code": "NI"
  },
  {
  "name": "Niger",
  "dial_code": "+227",
  "code": "NE"
  },
  {
  "name": "Nigeria",
  "dial_code": "+234",
  "code": "NG"
  },
  {
  "name": "Niue",
  "dial_code": "+683",
  "code": "NU"
  },
  {
  "name": "Norfolk Island",
  "dial_code": "+672",
  "code": "NF"
  },
  {
  "name": "Northern Mariana Islands",
  "dial_code": "+1670",
  "code": "MP"
  },
  {
  "name": "Norway",
  "dial_code": "+47",
  "code": "NO"
  },
  {
  "name": "Oman",
  "dial_code": "+968",
  "code": "OM"
  },
  {
  "name": "Pakistan",
  "dial_code": "+92",
  "code": "PK"
  },
  {
  "name": "Palau",
  "dial_code": "+680",
  "code": "PW"
  },
  {
  "name": "Palestinian Territory, Occupied",
  "dial_code": "+970",
  "code": "PS"
  },
  {
  "name": "Panama",
  "dial_code": "+507",
  "code": "PA"
  },
  {
  "name": "Papua New Guinea",
  "dial_code": "+675",
  "code": "PG"
  },
  {
  "name": "Paraguay",
  "dial_code": "+595",
  "code": "PY"
  },
  {
  "name": "Peru",
  "dial_code": "+51",
  "code": "PE"
  },
  {
  "name": "Philippines",
  "dial_code": "+63",
  "code": "PH"
  },
  {
  "name": "Pitcairn",
  "dial_code": "+872",
  "code": "PN"
  },
  {
  "name": "Poland",
  "dial_code": "+48",
  "code": "PL"
  },
  {
  "name": "Portugal",
  "dial_code": "+351",
  "code": "PT"
  },
  {
  "name": "Puerto Rico",
  "dial_code": "+1939",
  "code": "PR"
  },
  {
  "name": "Qatar",
  "dial_code": "+974",
  "code": "QA"
  },
  {
  "name": "Romania",
  "dial_code": "+40",
  "code": "RO"
  },
  {
  "name": "Russia",
  "dial_code": "+7",
  "code": "RU"
  },
  {
  "name": "Rwanda",
  "dial_code": "+250",
  "code": "RW"
  },
  {
  "name": "Reunion",
  "dial_code": "+262",
  "code": "RE"
  },
  {
  "name": "Saint Barthelemy",
  "dial_code": "+590",
  "code": "BL"
  },
  {
  "name": "Saint Helena, Ascension and Tristan Da Cunha",
  "dial_code": "+290",
  "code": "SH"
  },
  {
  "name": "Saint Kitts and Nevis",
  "dial_code": "+1869",
  "code": "KN"
  },
  {
  "name": "Saint Lucia",
  "dial_code": "+1758",
  "code": "LC"
  },
  {
  "name": "Saint Martin",
  "dial_code": "+590",
  "code": "MF"
  },
  {
  "name": "Saint Pierre and Miquelon",
  "dial_code": "+508",
  "code": "PM"
  },
  {
  "name": "Saint Vincent and the Grenadines",
  "dial_code": "+1784",
  "code": "VC"
  },
  {
  "name": "Samoa",
  "dial_code": "+685",
  "code": "WS"
  },
  {
  "name": "San Marino",
  "dial_code": "+378",
  "code": "SM"
  },
  {
  "name": "Sao Tome and Principe",
  "dial_code": "+239",
  "code": "ST"
  },
  {
  "name": "Saudi Arabia",
  "dial_code": "+966",
  "code": "SA"
  },
  {
  "name": "Senegal",
  "dial_code": "+221",
  "code": "SN"
  },
  {
  "name": "Serbia",
  "dial_code": "+381",
  "code": "RS"
  },
  {
  "name": "Seychelles",
  "dial_code": "+248",
  "code": "SC"
  },
  {
  "name": "Sierra Leone",
  "dial_code": "+232",
  "code": "SL"
  },
  {
  "name": "Singapore",
  "dial_code": "+65",
  "code": "SG"
  },
  {
  "name": "Slovakia",
  "dial_code": "+421",
  "code": "SK"
  },
  {
  "name": "Slovenia",
  "dial_code": "+386",
  "code": "SI"
  },
  {
  "name": "Solomon Islands",
  "dial_code": "+677",
  "code": "SB"
  },
  {
  "name": "Somalia",
  "dial_code": "+252",
  "code": "SO"
  },
  {
  "name": "South Africa",
  "dial_code": "+27",
  "code": "ZA"
  },
  {
  "name": "South Sudan",
  "dial_code": "+211",
  "code": "SS"
  },
  {
  "name": "South Georgia and the South Sandwich Islands",
  "dial_code": "+500",
  "code": "GS"
  },
  {
  "name": "Spain",
  "dial_code": "+34",
  "code": "ES"
  },
  {
  "name": "Sri Lanka",
  "dial_code": "+94",
  "code": "LK"
  },
  {
  "name": "Sudan",
  "dial_code": "+249",
  "code": "SD"
  },
  {
  "name": "Suriname",
  "dial_code": "+597",
  "code": "SR"
  },
  {
  "name": "Svalbard and Jan Mayen",
  "dial_code": "+47",
  "code": "SJ"
  },
  {
  "name": "Swaziland",
  "dial_code": "+268",
  "code": "SZ"
  },
  {
  "name": "Sweden",
  "dial_code": "+46",
  "code": "SE"
  },
  {
  "name": "Switzerland",
  "dial_code": "+41",
  "code": "CH"
  },
  {
  "name": "Syrian Arab Republic",
  "dial_code": "+963",
  "code": "SY"
  },
  {
  "name": "Taiwan",
  "dial_code": "+886",
  "code": "TW"
  },
  {
  "name": "Tajikistan",
  "dial_code": "+992",
  "code": "TJ"
  },
  {
  "name": "Tanzania, United Republic of Tanzania",
  "dial_code": "+255",
  "code": "TZ"
  },
  {
  "name": "Thailand",
  "dial_code": "+66",
  "code": "TH"
  },
  {
  "name": "Timor-Leste",
  "dial_code": "+670",
  "code": "TL"
  },
  {
  "name": "Togo",
  "dial_code": "+228",
  "code": "TG"
  },
  {
  "name": "Tokelau",
  "dial_code": "+690",
  "code": "TK"
  },
  {
  "name": "Tonga",
  "dial_code": "+676",
  "code": "TO"
  },
  {
  "name": "Trinidad and Tobago",
  "dial_code": "+1868",
  "code": "TT"
  },
  {
  "name": "Tunisia",
  "dial_code": "+216",
  "code": "TN"
  },
  {
  "name": "Turkey",
  "dial_code": "+90",
  "code": "TR"
  },
  {
  "name": "Turkmenistan",
  "dial_code": "+993",
  "code": "TM"
  },
  {
  "name": "Turks and Caicos Islands",
  "dial_code": "+1649",
  "code": "TC"
  },
  {
  "name": "Tuvalu",
  "dial_code": "+688",
  "code": "TV"
  },
  {
  "name": "Uganda",
  "dial_code": "+256",
  "code": "UG"
  },
  {
  "name": "Ukraine",
  "dial_code": "+380",
  "code": "UA"
  },
  {
  "name": "United Arab Emirates",
  "dial_code": "+971",
  "code": "AE"
  },
  {
  "name": "United Kingdom",
  "dial_code": "+44",
  "code": "GB"
  },
  {
  "name": "United States",
  "dial_code": "+1",
  "code": "US"
  },
  {
  "name": "Uruguay",
  "dial_code": "+598",
  "code": "UY"
  },
  {
  "name": "Uzbekistan",
  "dial_code": "+998",
  "code": "UZ"
  },
  {
  "name": "Vanuatu",
  "dial_code": "+678",
  "code": "VU"
  },
  {
  "name": "Venezuela, Bolivarian Republic of Venezuela",
  "dial_code": "+58",
  "code": "VE"
  },
  {
  "name": "Vietnam",
  "dial_code": "+84",
  "code": "VN"
  },
  {
  "name": "Virgin Islands, British",
  "dial_code": "+1284",
  "code": "VG"
  },
  {
  "name": "Virgin Islands, U.S.",
  "dial_code": "+1340",
  "code": "VI"
  },
  {
  "name": "Wallis and Futuna",
  "dial_code": "+681",
  "code": "WF"
  },
  {
  "name": "Yemen",
  "dial_code": "+967",
  "code": "YE"
  },
  {
  "name": "Zambia",
  "dial_code": "+260",
  "code": "ZM"
  },
  {
  "name": "Zimbabwe",
  "dial_code": "+263",
  "code": "ZW"
  }
  ]
