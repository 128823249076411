import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { AdminService } from '../../services/admin.service';
import * as CONSTANT from '../../services/constant';
import { loginModel, resetPasswordModel } from '../../shared/models/login-models';
import { HttpParams } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { apiUrl } from '../../global/global';
import * as CryptoJS from 'crypto-js';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    
    isLogin : boolean = true;
    public form: FormGroup;
    public resetPasswordForm: FormGroup;
    showError = false;
    showErrorForgot = false;
    show = true;
    public loading = false;
    image: any;
    showPwd: boolean;
    loginModel = new loginModel();
    resetPasswordModel = new resetPasswordModel();
    rememberme = false;
    tokenFromUI = '0123456789123456';
    isRemember = false;
    accessToken: any;

    constructor(private formBuilder: FormBuilder, private router: Router, private api: AdminService) {
        
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email ])],
            password: ['', Validators.compose([Validators.required] ) ]
        });
        this.showPwd = false;

        this.resetPasswordForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email ])],
        })
        this.accessToken = localStorage.getItem('accessToken');
    }

    ngOnInit(): void {
        let data = localStorage.getItem('accessToken');
        this.show = true;
        if (data)
            this.router.navigate(['/dashboard'],{ replaceUrl: true });
            let rememberme = localStorage.getItem('rememberme');
            console.log(rememberme, "rememberme");
            if (rememberme == '1') {
              this.loginModel.email = localStorage.getItem('email');
              this.decryptUsingAES256(localStorage.getItem('password'));
              this.isRemember = true;
              this.rememberme = true;
            }
    }
    password() {
        this.show = !this.show;
    }
    loginFun() {
        console.log("thr")
        console.log(this.form);
        if (this.form.valid) {
            this.api.apiEndpoint = environment.apiUrl;
            this.loading = true;
            //this.api.loader(true);
            const data = {
                'email': this.loginModel.email,
                'password': this.loginModel.password,
                //  'deviceToken': this.msg.getFcmToken()
            };
            this.api.upateData(apiUrl._adminLogin, data)
                .subscribe(async (res) => {
                    console.log(res, "login============================");
                    this.loading = false;
                    localStorage.setItem('accessToken', res.data.accessToken);
                    // localStorage.setItem('firstName', res.data.firstName);
                    // localStorage.setItem('lastName', res.data.lastName)
                    // localStorage.setItem('type', res.data.type);
                    // localStorage.setItem('loginEmail', res.data.email);
                    // localStorage.setItem('userId', res.data._id);
                    localStorage.setItem('userData', JSON.stringify(res.data));
                    let userData = JSON.parse(localStorage.getItem('userData'));
                    //console.log(userData);
                    //console.log( localStorage, "res=============");                
                    let param = new HttpParams();

                    this.router.navigate(['/dashboard'],{ replaceUrl: true });
                    this.api.toast('STS Admin', 'Login Successfull!');


                    if (this.rememberme) {
                        let encrptedPassword = await this.encryptUsingAES256(this.loginModel.password);
                        localStorage.setItem('rememberme', '1');
                        localStorage.setItem('email', this.loginModel.email);
                        localStorage.setItem('password', encrptedPassword.toString());
                    } else {
                        localStorage.setItem('rememberme', '0');
                        localStorage.setItem('email', '');
                        localStorage.setItem('password', '');
                    }
                },
                    err => {
                        console.log(err, "errr");
                        this.loading = false;
                    });
        } else {
            this.showError = true;
            setTimeout(() => {this.showError = false}, 5000  ) 
        }
    }

    forgotPassword() {
        this.show = false;
    }

    encryptUsingAES256(value) {
        return new Promise((resolve, reject) => {
            let ciphertext = CryptoJS.AES.encrypt(value, this.tokenFromUI);
            resolve(ciphertext);
        });

    }
    decryptUsingAES256(value) {
        let bytes = CryptoJS.AES.decrypt(value.toString(), this.tokenFromUI);
        let plaintext = bytes.toString(CryptoJS.enc.Utf8);
        this.loginModel.password = plaintext;
    }

    backToLogin() {
        this.show = true;
    }



    setServer(event) {
        localStorage.setItem('server', event.target.value)
    }

    changeRememberme(event) {
        console.log("checked", event.target.checked);
        this.rememberme = event.target.checked;
    }

    // forgot password
    resetPassword() {
        console.log(this.resetPasswordForm);
        if (this.resetPasswordForm.valid) {
          this.api.apiEndpoint = environment.apiUrl;
          this.loading = true;
          const data = {
            'email': this.resetPasswordModel.email
          };
          this.api.upateData(apiUrl._forgotPassword, data)
            .subscribe(res => {
              this.loading = false;
              swal.fire({
                icon: 'success',
                title: '<h2>ShowToStream Admin</h2>',
                html: '<p>Your password email successfully sent on your registered email address</p>',
                showCloseButton: false,
                width: 500 
              })
              this.router.navigate(['/']);
            },
              err => {
                console.log(err);
                this.loading = false;
              });
        } else {
          this.showErrorForgot = true;
          setTimeout(() => {this.showErrorForgot = false}, 5000  )
        }
      }

}
