import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment} from '../../environments/environment';
import swal from "sweetalert2";
import { Router } from '@angular/router';

import { generalModel, icons, menu, BtnText, permission, tabArray, optionArray } from '../shared/models/general-models';
import { BehaviorSubject, Subject } from 'rxjs';
import { apiUrl } from '../global/global';

@Injectable({
  providedIn: 'root'
}) 
export class AdminService {
  apiEndpoint: string = '';
  showLoader: boolean = true;
  icon = icons;
  access_token: any;
  menu = menu;
  btnText = BtnText;
  generalModel = new generalModel();
  Permission = permission;
  checkPresenterApproval = new BehaviorSubject(null);
  checkContentApproval = new BehaviorSubject(null);
  
  public _albums = [];
  constructor(private http: HttpClient, private router: Router) {
    this.apiEndpoint = environment.apiUrl;
    this.access_token = localStorage.getItem('access_token');
    //console.log(this.access_token, " this.access_token")
    // if(!this.access_token){
    //   this.router.navigate(['/login']);
    // }

  }

  httpOptions = {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    })
    }

  getData(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  getDetail(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }

  getDetail1(url, param) {
    return this.http.get<any>(this.apiEndpoint + url, { params: param });
  }


  putData(url, param) {
    return this.http.put<any>(this.apiEndpoint + url,  param );
  }

   postData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, value);
  }

  upateData(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  deleteUserData(url, value) {
    return this.http.post<any>(this.apiEndpoint + url, {userId: value});
  }
  patchUpdate(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value); 
  }

  postUpdate(url, value) {
    return this.http.put<any>(this.apiEndpoint + url, value);
  }

  postNewData(url, key, value) {
    return this.http.post<any>(this.apiEndpoint + url, key, value);
  } 
  updateData(url, value) {
    return this.http.patch<any>(this.apiEndpoint + url, value);
  }

  deleteData(url,  param) {
    return this.http.delete<any>(this.apiEndpoint +url,  {params: param});
  }


  /*codes */
  // getDataList(url, value) {
  //   return this.http.get<any>(this.apiEndpoint + url, value);
  // }

  

  showAlert(title: string, text: string) {
    swal.fire({
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 3000
    })

  }

  errorAlert(text, status) {
    swal.fire({
      title: 'Oops...',
      text: text,    
    }).then((result) => {
      if (status) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('type-redirect');
        localStorage.removeItem('image');
        localStorage.removeItem('permissionData');
        this.router.navigate(['/login']);
      }
    })
  }
 
  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
 }

  toast(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 6000,
      background: '#5ECB5E',
      icon: "success",
    });
    toast.fire({
      title: ' ' + title,
      text: text
    })
  }

  toastAlert(title, text) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 6000,
      background: '#AE0019',
      icon: "error",
    });
    toast.fire({
      title: ' ' + title,
      text: text
    })
  }


  // Show and hide Loader
  private loaderSubject = new Subject<Boolean>();
  loaderState = this.loaderSubject.asObservable();

  loader(value) { this.loaderSubject.next(value); }

  setdata(data) {
    for (let x of tabArray) {
      let array1 = {}
      for (let y of x.value) {
        array1[optionArray[y].name] = (data[x.name] == undefined || data[x.name][optionArray[y].name] == undefined ? false : data[x.name][optionArray[y].name]);
      }
      permission[x.name] = array1;
    }
    permission['status'] = "updated";
    return true;
  }

}
