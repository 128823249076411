export const apiUrl = {
    _adminLogin:'admin/login',
    _forgotPassword:'admin/forgotPassword',
    _resetPassword:'admin/resetPassword',
    _logout: 'admin/logout',

    _gerUserList: 'admin/users',
    _getUserById: 'admin/userDetails',
    _updateUser: 'admin/userUpdate',
    _blockUser: 'admin/userBlock',
    _deleteUser:'admin/resource',
    _getPurchasedShow : 'admin/userContentDetails',
    
    _getPresenterApprovalList: 'admin/presenterApprovals',
    _getPresenterApprovalById: 'admin/presenterApprovalDetails',
    _updatePresenter : 'admin/presenter/ApproveOrDecline',
    _getPresenterVideos : 'admin/presenterContentList',
    _approveDeclinePresenterVideo : 'admin/approveDeclineContent',

    _getPresentersList: 'admin/presenters',
    _blockPresenter: 'admin/presenterBlock',
    _getPresenterById : 'admin/presenterDetails',
    _editPresenter : 'admin/presenterUpdate',

    _getNotification : 'admin/notifications',
    _getNotificationById : 'admin/notificationDetails',
    _getAllCountries : 'common/countries',
    _addNotification : 'admin/sendNotification',

    _getContentList : 'admin/contents',
    _updateContent : 'admin/content/ApproveOrDecline',
    _getContentById : 'admin/contentDetails',
    

    _getPaymentList : 'admin/payments',
    _getPaymentDetails : 'admin/paymentDetails',

    _getPromoCodeList : 'admin/promocodes',
    _getPromoCodeDetails: 'admin/promocode',
    _getPromoCodeUsageList: 'admin/promocodeUsage',
    _getPromoCodeUsageDetails: 'admin/promocodeUsageDetails',
    _addPromoCode : 'admin/createPromoCode',
    _editPromoCode : 'admin/promocode',

    _getSubAdminList : 'admin/subAdmins',
    _getSubAdminDetails : 'admin/subAdminDetails',
    _blockSubAdmin : 'admin/subAdminBlock',
    _addSubAdmin : 'admin/createSubAdmin',
    _editSubAdmin : 'admin/subAdminUpdate',

    _getSleevesList : 'admin/sleeves',
    _getSleevesCategory : 'admin/categorySleeves',
    _getSleevesGeners : 'admin/genreSleeves',
    _deleteSleeves : 'admin/sleeveDelete',
    _sleevesManage : 'admin/sleevesManage',
    _searchSleevesContent : 'admin/contentSearch',

    _getDashboard : 'admin/dashboardData',
    _getTopVideos : 'admin/topVideos',
    _getTransaction : 'admin/transactionData',
    _getRevenue : 'admin/revenueData',
    _getUsers : 'admin/dashboardUserData',
    _getPresenters : 'admin/dashboardPresenterData',
    _getDashboardGraphYears : 'admin/dashboardYearsData',

    _editAdminProfile : 'admin/editProfile',
    _getAdmin : 'admin',
    _changePassword : 'admin/changePassword',

    _getCategories: 'common/categories',
    _getGenres: 'common/genres',
    _getUploads: 'presenter/uploadList',
    _getScheduleUploads: 'presenter/scheduleList',
    _getCountries: 'common/countries',
    _getLanguages: 'common/languages',
    _UploadContentNew: 'admin/uploadContent',
    _createPromoCode: 'admin/createPresenterPromoCode',
    _UploadContent: 'admin/uploadContent',
    _changeContentStatus: 'admin/changeContentStatus',
    _getPresenterContent : 'admin/presenterContentDetails',
    _getS3Detail:'admin/getS3Detail',
    _getContentFee: 'user/contentfees',
    _addContentFee: 'user/addcontentfees',
    _contentDelete: 'admin/deleteContent',
    _presenterPurchasedVideos: 'admin/presenterPurchasedVideos',
    _videoPurchasedBy: 'admin/videosPurchasedHistory',
    _markedAsFree: 'admin/markedAsFree',

};
