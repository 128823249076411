import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import swal from "sweetalert2";
import { Router, ActivatedRoute } from '@angular/router';
import *  as CONSTANT from "../../services/constant";
import { DataSharingService } from '../../services/data.share.service';
import moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  firstName: any;
  lastName: any;
  email: any;
  profilePic: any;
  adminsData : any = [];
  accessToken: any;
  adminID : string;
  search : string;
  showError = false;
  showNofound = false;
  img : any;
  event : any;
  show = false; 
  notifications:any;
  length:any;
  closeSearch = false;
  pagination = { limit: CONSTANT.LIMIT, maxSize: CONSTANT.MAX_SIZE, skip: 1, totalItems: null }
  currentPage: number = 1;
  limitRecordList = CONSTANT.LIMIT_RECORD_LIST;
  

  constructor(private router: Router, private adminService: AdminService, private dataSharingService: DataSharingService) { }

  ngOnInit(): void {
    var userData;
    userData = JSON.parse(localStorage.getItem('userData'));
    this.adminID = userData._id;
    this.firstName = userData.firstName;
    this.lastName = userData.lastName
    //console.log(this.adminID);
    this.adminDetails();
    this.headerNotification();
   
    this.dataSharingService.headerProfile.subscribe(res => {
      //console.log(res);
      this.adminDetails();
      this.headerNotification();
    })
    
  }


convertTime(time){
  return moment.duration(moment().diff(time)).humanize();
}

/* headerNotification */
headerNotification() {
  let params = new HttpParams();
  params = params.append('skip',  this.pagination.skip.toString());
  
  this.adminService.postData(apiUrl._getNotification , params).subscribe(res => {
    this.notifications = res.data;
    this.length = res.data.length;
    //console.log(this.length);
    //console.log(this.notifications, "===notificationList ");
    this.showNofound= true;
  },
  err => {
    console.log(err);
  });
}
  
  
  logout() {

    swal.fire({
      icon: "warning",
      text: 'Are you sure you want to logout ?',
      showCancelButton: true,
      confirmButtonColor: '#2FC863',
      cancelButtonColor: '#2C2C2C',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem('adminToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('permissionData');
        localStorage.removeItem('permissionId');
        localStorage.removeItem('s3Url');
        localStorage.removeItem('s3Folders');
        localStorage.removeItem('userData');
        
        this.router.navigate(['/login']);
      }
    });
  }

  adminDetails() {
    let params = new HttpParams();
    
    this.adminService.getDetail(apiUrl._getAdmin , params ).subscribe(res => {
      //console.log(res.data);
      this.adminsData = res.data;
      //console.log(this.adminsData, "===ProfileDetails ");
      this.showNofound= true;
    },
      err => {
        console.log(err);
      });
  }


  toggleNavbar(event: any) {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("sidebar-mini");
      if (this.show = !this.show) {
      } else {
      body.classList.remove("has-animation sidebar-mini");
      }
  }
    
  onMenuSelect(event: any) {
    this.toggleNavbar(event);
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove("sidebar-mini");
  }

    

}
