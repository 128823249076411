<!-- START SIDEBAR-->
<nav class="page-sidebar navbar-collapse" id="navbarNavDropdown">
    <div id="sidebar-collapse" class="scroller" data-height="100%" data-color="#ccc">
        <ul class="side-menu">
            <li routerLinkActive="active" (click)="onMenuSelect(event)" *ngFor="let permission of menu">
                <a href="javascript:;" routerLink="{{permission.path}}">
                    <span class="{{permission.icon}}"></span>
                    <span class="nav-label">{{permission.name}}<span class="notificationAlert" *ngIf="(permission.name == 'Presenters Approval' && showPresenterFlag)||(permission.name == 'Content' && showContentFlag)"></span></span>
                </a>
            </li>
            <!-- <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a  href="javascript:;" routerLink="/users" >
                    <span class="mdi mdi-account"></span>
                    <span class="nav-label">Users </span>
                </a>
            </li>
            <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a href="javascript:;" routerLink="/presenter-approval">
                    <span class="mdi mdi-account-check"></span>
                    <span class="nav-label">Presenter’s Approval </span>
                </a>
            </li>
            <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a href="javascript:;" routerLink="/presenters">
                    <span class="mdi mdi-account-card-details"></span>
                    <span class="nav-label">Presenters </span>
                </a>
            </li>

            <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a href="javascript:;" routerLink="/sleeves">
                    <span class="mdi mdi-tshirt-crew"></span>
                    <span class="nav-label">Sleeves </span>
                </a>
            </li>
            <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a href="javascript:;" routerLink="/content">
                    <span class="mdi mdi-account-edit"></span>
                    <span class="nav-label">Content </span>
                </a>
            </li>
            <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a href="javascript:;" routerLink="/payment">
                    <span class="mdi mdi-cards"></span>
                    <span class="nav-label">Payment </span>
                </a>
            </li>
            <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a href="javascript:;" routerLink="/promo-code">
                    <span class="mdi mdi-gift"></span>
                    <span class="nav-label">Promo Code </span>
                </a>
            </li>
            <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a href="javascript:;" routerLink="/notifications">
                    <span class="mdi mdi-bell"></span>
                    <span class="nav-label">Notifications</span>
                </a>
            </li>
            <li routerLinkActive="active" (click)="onMenuSelect(event)">
                <a href="javascript:;" routerLink="/sub-admin">
                    <span class="mdi mdi-account"></span>
                    <span class="nav-label">Sub Admin </span>
                </a>
            </li> -->
        </ul>
    </div>
</nav>
<!-- END SIDEBAR-->