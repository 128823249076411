import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './comman/header/header.component';
import { LoaderComponent } from './comman/loader/loader.component';
import { PageNotFoundComponent } from './comman/page-not-found/page-not-found.component';
import { LoginComponent } from './external/login/login.component';
import { ForgotPasswordComponent } from './external/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './external/reset-password/reset-password.component';
import { CustPipe } from './pipe/cust.pipe';
import { InternalComponent } from './internal/internal.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SidebarComponent } from './comman/sidebar/sidebar.component';
import { LinkExpiredComponent } from './external/link-expired/link-expired.component';
import {AsyncPipe, CommonModule} from '@angular/common';
import { InterceptorService } from './services/interceptor.service';
import { DataSharingService } from './services/data.share.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoaderComponent,
    PageNotFoundComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CustPipe,
    InternalComponent,
    SidebarComponent,
    LinkExpiredComponent,
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({
      echarts 
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgMultiSelectDropDownModule.forRoot(),
    NgxSpinnerModule,
    ImageCropperModule
  ],
  providers: [AsyncPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true 
    },
    DataSharingService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
