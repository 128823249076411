<!-- START HEADER-->
<header class="header">
    <div class="page-brand">
        <a class="link" routerLink="/dashboard"><img class="headerImg" src="../../../assets/images/logo.svg"></a>
    </div>
    <div class="flexbox flex-1">
        <!-- START TOP-LEFT TOOLBAR-->
        <ul class="nav navbar-toolbar">
            <li>
                <a (click)="onMenuSelect(event)" class="nav-link sidebar-toggler js-sidebar-toggler navbar-toggler"
                    href="javascript:;" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </a>
            </li>
        </ul>
        <!-- END TOP-LEFT TOOLBAR-->
        <!-- START TOP-RIGHT TOOLBAR-->
        <ul class="nav navbar-toolbar">
            <!-- <li class="dropdown dropdown-notification" ngbDropdown autoClose="true">
                <a class="nav-link dropdown-toggle toolbar-icon" data-toggle="dropdown" id="dropdownMenuLink" ngbDropdownToggle>
                    <i class="mdi mdi-bell rel" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Notification"><span class="notify-signal"></span></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-right dropdown-menu-media" ngbDropdownMenu aria-labelledby="dropdownMenuLink">
                    <li class="dropdown-menu-header">
                        <div>
                            <span class="font-18"><strong>{{length}} New</strong> Notifications</span>
                            <a class="text-muted font-13" [routerLink]="'/notifications'" ngbDropdownItem>view all</a>
                        </div>
                    </li>
                    <div class="p-3">
                        <ul class="timeline scroller" data-height="320px" data-color="#71808f" [style.overflow]="'auto'" [style.height.px]="'250'">
                            <li *ngFor="let notification of notifications" class="timeline-item"> {{notification.title}} <small class="float-right text-muted ml-2 nowrap">{{convertTime(notification.createdAt)}}</small></li>
                        </ul>
                    </div>
                </ul>
            </li> -->
            <li class="dropdown dropdown-user" ngbDropdown autoClose="true" >
                <a class="nav-link dropdown-toggle link" data-toggle="dropdown" id="dropdownMenuLink" ngbDropdownToggle>
                    <img style="width: 50px;height: 50px;" src="{{adminsData.profilePic == '' ? '../../../../../assets/images/ShowToStreamLogo.svg' : adminsData.profilePic}}" alt="image" />
                </a>
                <div class="dropdown-menu dropdown-arrow dropdown-menu-right admin-dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownMenuLink" >
                    <div>
                        <div class="dropdown-arrow"></div>
                        <div class="dropdown-header">
                            <div class="admin-avatar">
                                <img    src="{{adminsData.profilePic == '' ? '../../../../../assets/images/ShowToStreamLogo.svg' : adminsData.profilePic}}" alt="image" />
                            </div>
                            <div>
                                <h5 class="font-strong">{{adminsData.firstName}} {{adminsData.lastName}}</h5>
                                <div>
                                    <span class="admin-badge mr-3"><span class="mdi mdi-email"></span> {{adminsData.email}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="admin-menu-features">
                            <a class="admin-features-item" [routerLink]="'/admin-profile'" ngbDropdownItem>
                                <span class="mdi mdi-account-outline"></span>
                                <span>PROFILE</span>
                            </a>
                            <a class="admin-features-item" [routerLink]="'/admin-profile/change-password'" ngbDropdownItem>
                                <span class="mdi mdi-lock-outline"></span>
                                <span>CHANGE PASSWORD </span>
                            </a>
                            <a class="admin-features-item" href="javascript:;" (click)="logout()" ngbDropdownItem>
                                <span class="mdi mdi-arrow-collapse-right"></span>
                                <span>LOGOUT</span>
                            </a>
                        </div>
                    </div>
                
                </div>
                    
            </li>
        </ul>
        <!-- END TOP-RIGHT TOOLBAR-->
    </div>
</header>
<!-- END HEADER-->