import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { apiUrl } from 'src/app/global/global';
import { AdminService } from 'src/app/services/admin.service';
import swal from "sweetalert2";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  show = true;
  event : any;
  permissions : any = [];
  userData : any = [];
  menu : any = [];
  showPresenterFlag:boolean = false;
  showContentFlag:boolean= false;
  constructor(private adminService: AdminService) {

   }

  ngOnInit(): void {
    
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.permissions = this.userData.permissions;
    for(let i=0;i<this.permissions.length;i++){
      if (this.permissions[i].modules == 'Dashboard' || this.permissions[i].sequence == 1) {
        // this.menu.push({ name: 'Agency Management', path: 'agent', value: [] })
        this.menu.push({ name: 'Dashboard', path: '/dashboard', icon: 'mdi mdi-home', value: [] })
        }
        if (this.permissions[i].modules == 'User' || this.permissions[i].sequence == 2) {
        this.menu.push({ name: 'Users', path: '/users', icon: 'mdi mdi-account', value: [] })
        }
        if (this.permissions[i].modules == 'PresentersApproval' || this.permissions[i].sequence == 3) {
          this.menu.push({ name: 'Presenters Approval', path: '/presenter-approval', icon: 'mdi mdi-account-check', value: [] })
        }
        if (this.permissions[i].modules == 'Presenters' || this.permissions[i].sequence == 4) {
          this.menu.push({ name: 'Presenters', path: '/presenters', icon: 'mdi mdi-account-card-details', value: [] })
        }
        if (this.permissions[i].modules == 'Sleeves' || this.permissions[i].sequence == 5) {
          this.menu.push({ name: 'Sleeves', path: '/sleeves', icon: 'mdi mdi-tshirt-crew', value: [] })
        }
        if (this.permissions[i].modules == 'Content' || this.permissions[i].sequence == 6) {
          this.menu.push({ name: 'Content', path: '/content', icon: 'mdi mdi-account-edit', value: [] })
        }
        if (this.permissions[i].modules == 'Payment' || this.permissions[i].sequence == 7) {
          this.menu.push({ name: 'Payment', path: '/payment', icon: 'mdi mdi-cards', value: [] })
        }
        if (this.permissions[i].modules == 'PromoCode' || this.permissions[i].sequence == 8) {
          this.menu.push({ name: 'Promo Code', path: '/promo-code', icon: 'mdi mdi-gift', value: [] })
        }
        if (this.permissions[i].modules == 'Notifications' || this.permissions[i].sequence == 9) {
          this.menu.push({ name: 'Notifications', path: '/notifications', icon: 'mdi mdi-bell', value: [] })
        }
        if (this.permissions[i].modules == 'SubAdmin' || this.permissions[i].sequence == 10) {
          this.menu.push({ name: 'Sub Admin', path: '/sub-admin', icon: 'mdi mdi-account', value: [] })
        }
        if (this.permissions[i].modules == 'SetContentPrice' || this.permissions[i].sequence == 11) {
          this.menu.push({ name: 'Set Content Price', path: '/set-content-price', icon: 'mdi mdi-cards', value: [] })
        }
        //console.log(this.menu);
      }

      this.adminService.checkPresenterApproval.subscribe(res=>{
        this.getPresenterApprovalList();
      })
      this.adminService.checkContentApproval.subscribe(res=>{
        this.getContentList();
      })
    }
    
    
  
  inProgress(){
    swal.fire(
      'info',
      'Work in progress.',
      'info'
    )
  }

  // toggleNavbar(event: any) {
  //   let body = document.getElementsByTagName('body')[0];
  //   body.classList.add("sidebar-mini");
  //     if (this.show = !this.show) {
  //     } else {
  //     body.classList.remove("has-animation sidebar-mini");
  //     }
  //   }

    onMenuSelect(event: any) {
    //this.toggleNavbar(event);
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove("sidebar-mini");
    }

    // Get Presenter Approval List
    getPresenterApprovalList(){
      let params = new HttpParams();
      params = params.append('skip',  '1');
      params = params.append('limit', '10');
      this.adminService.postData(apiUrl._getPresenterApprovalList ,params).subscribe(res => {
        console.log("Presenter:::::",res.data);
        if(res.data && res.data.length){
          this.showPresenterFlag = true;
        }else{
          this.showPresenterFlag = false;
        }
      
      },
        err => {
          console.log(err);
        });
    }

    getContentList() {
      let params = new HttpParams();
      params = params.append('skip',  '1');
      params = params.append('limit', '10');
      // params = params.append('search', this.search || '');
     
      
        this.adminService.postData(apiUrl._getContentList , params).subscribe(res => {
        //console.log(res.data);
        if(res.data && res.data.length){
          this.showContentFlag = true;
        }else{
          this.showContentFlag = false;
        }
       
      },
        err => {
          console.log(err);
        });
    }

}
